import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import { FormSearchByString } from "./FormSearchByString";
import { ReadInvoices } from "../graphql/invoiceTool";
import {
    InvoiceKind,
    InvoiceResultFragment,
    ReadAllInvoicesQuery,
    ReadAllInvoicesQueryVariables,
    TransactionStatus,
} from "../types/graphql";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { colors } from "../theme";
import { Error } from "./Error";
import { Loading } from "./Loading";

type OptionType<T> = {
    value: T;
    label: string;
};

const filterStatusOptions: OptionType<TransactionStatus>[] = [
    { value: TransactionStatus.Unpaid, label: "Unpaid" },
    { value: TransactionStatus.Scheduled, label: "Scheduled" },
    { value: TransactionStatus.Paid, label: "Paid" },
];

const filterKindOptions: OptionType<InvoiceKind>[] = [
    { value: InvoiceKind.Default, label: "Imported" },
    { value: InvoiceKind.Einvoice, label: "EFaktura" },
    { value: InvoiceKind.Debt, label: "Debt" },
];

interface IProps {
    userId?: string;
    issuerId?: string;
}

const defaultFromDate = moment().add(-1, "days").toDate();
const defaultToDate = moment().add(1, "days").toDate();

const dataLength = 25;

export const InvoicesOverview = ({ userId, issuerId }: IProps) => {
    const isFirstFetch = useRef(true);
    const isChangedFetch = useRef(false);
    const [items, setItems] = useState<Array<InvoiceResultFragment>>([]);
    const [readInvoices, { data, loading, error }] = useLazyQuery<
        ReadAllInvoicesQuery,
        ReadAllInvoicesQueryVariables
    >(ReadInvoices);

    // Filters
    const [searchingTerm, setSearchingTerm] = useState<string | null>(null);
    const [fromDate, setFromDate] = useState<Date | null>(defaultFromDate);
    const [toDate, setToDate] = useState<Date | null>(defaultToDate);
    const [filterByStatus, setFilterByStatus] = useState<TransactionStatus>();
    const [filterByKind, setFilterByKind] = useState<InvoiceKind>();
    const [invoiceIdSearch, setInvoiceIdSearch] = useState<string | null>(null);

    const fetchData = useCallback(
        async (resetCursor?: boolean) => {
            // Avoid general search
            const isFilterApplied =
                searchingTerm ||
                (fromDate && toDate) ||
                filterByStatus ||
                filterByKind ||
                invoiceIdSearch;
            if (!isFilterApplied) {
                return;
            }

            let after = data?.result?.pageInfo?.endCursor;
            if (resetCursor) {
                setItems([]);
                after = null;
            }
            await readInvoices({
                variables: {
                    first: dataLength,
                    after,
                    status: filterByStatus,
                    kind: filterByKind,
                    fromDate: fromDate?.toISOString(),
                    toDate: toDate?.toISOString(),
                    searchingTerm,
                    invoiceId: invoiceIdSearch,
                    issuerId,
                    userId,
                },
            });
        },
        [
            data,
            filterByKind,
            filterByStatus,
            fromDate,
            issuerId,
            readInvoices,
            searchingTerm,
            toDate,
            userId,
            invoiceIdSearch
        ]
    );

    useEffect(() => {
        if (isFirstFetch.current) {
            fetchData();
            isFirstFetch.current = false;
        }
    }, [fetchData]);

    useEffect(() => {
        if (isChangedFetch.current) {
            fetchData(true);
            isChangedFetch.current = false;
        }
    }, [fetchData, isChangedFetch]);

    useEffect(() => {
        const edges = data?.result?.edges;
        if (!loading && !error && edges) {
            setItems((prev) => [
                ...prev,
                ...edges.map((v) => v?.node as InvoiceResultFragment),
            ]);
        }
    }, [loading, error, data]);

    const onCardClick = useCallback((id: string) => {
        window.open(`/invoices/${id}`, "_blank");
    }, []);

    const onUserClick = useCallback((id: string) => {
        window.open(`/user/${id}`, "_blank");
    }, []);

    const loadMoreOnScroll = () => {
        setTimeout(() => {
            fetchData();
        }, 500);
    };

    const itemsToRender = useMemo(() => {
        return items.map((v) => (
            <tr
                key={v.id}
                onClick={() => onCardClick(v.id)}
                style={{ cursor: "pointer" }}
            >
                <td>{v.id}</td>
                <td>{v.issuer.name}</td>
                <td onClick={() => onUserClick(v.user.id)}>{v.user.firstname}</td>
                <td>
                    <Badge bg={"secondary"}>
                        {moment(v.invoiceDate).format("DD MMM YYYY")}
                    </Badge>
                </td>
                <td>
                    <Badge bg={"warning"}>
                        {moment(v.dueDate).format("DD MMM YYYY")}
                    </Badge>
                </td>
                <td>{v.reference}</td>
                <td>
                    {v.lastTransaction.amount} {v.currency.symbol}
                </td>
                <td>
                    {v.fee
                        ? `${v.fee?.amount} kr (${v.fee?.percentage}% - ${v.fee?.reason})`
                        : "-"}
                </td>
            </tr>
        ));
    }, [items, onCardClick]);

    return (
        <Card>
            <Card.Header>
                <h5 className="dark">Invoices</h5>
            </Card.Header>
            <Card.Header>
                <p>
                    Use filters to retrieve invoices. Because of the weight of
                    information, we cannot provide a complete list of invoices without
                    using a filter.
                </p>
                <Row>
                    <Col sm={12} md={3}>
                        <FormSearchByString
                            placeholder="References, KID, cust.no etc"
                            onSubmit={async (name) => {
                                setSearchingTerm(name !== "" ? name : null);
                                isChangedFetch.current = true;
                            }}
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <FormSearchByString
                            placeholder="Invoice id"
                            onSubmit={async (invoiceId) => {
                                setInvoiceIdSearch(invoiceId !== "" ? invoiceId : null);
                                isChangedFetch.current = true;
                            }}
                        />
                    </Col>
                    <Col sm={12} md={3}>
                        {fromDate && toDate ? (
                            <>
                                <DateRange
                                    ranges={[
                                        {
                                            startDate: fromDate,
                                            endDate: toDate,
                                            key: "filterByDate",
                                        },
                                    ]}
                                    onChange={(v: any) => {
                                        const { startDate, endDate } = v.filterByDate;
                                        setFromDate(startDate);
                                        setToDate(endDate);
                                        isChangedFetch.current = true;
                                    }}
                                    showSelectionPreview={false}
                                    rangeColors={[colors.info]}
                                    showMonthAndYearPickers={false}
                                />
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setFromDate(null);
                                        setToDate(null);
                                        isChangedFetch.current = true;
                                    }}
                                >
                                    Clear
                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setFromDate(defaultFromDate);
                                    setToDate(defaultToDate);
                                    isChangedFetch.current = true;
                                }}
                            >
                                Filter by dates
                            </Button>
                        )}
                    </Col>
                    <Col sm={12} md={2}>
                        <Select
                            defaultValue={null}
                            // TODO: See issue: https://github.com/JedWatson/react-select/issues/3632
                            onChange={(v: any) => {
                                const vv = v?.value as TransactionStatus;
                                setFilterByStatus(vv);
                                isChangedFetch.current = true;
                            }}
                            placeholder={"Status filter"}
                            options={filterStatusOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <Select
                            defaultValue={null}
                            // TODO: See issue: https://github.com/JedWatson/react-select/issues/3632
                            onChange={(v: any) => {
                                const vv = v?.value as InvoiceKind;
                                setFilterByKind(vv);
                                isChangedFetch.current = true;
                            }}
                            placeholder={"Kind filter"}
                            options={filterKindOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isClearable
                        />
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Error error={error?.graphQLErrors[0].message}/>
                <Loading isLoading={loading}/>
                <InfiniteScroll
                    hasMore={data?.result?.pageInfo.hasNextPage || false}
                    loader={<Loading isLoading/>}
                    dataLength={items.length}
                    next={loadMoreOnScroll}
                >
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Issuer name</th>
                            <th>First name</th>
                            <th>Invoice date</th>
                            <th>Due date</th>
                            <th>Reference</th>
                            <th>Amount</th>
                            <th>Fee</th>
                        </tr>
                        </thead>
                        <tbody>{itemsToRender}</tbody>
                    </Table>
                </InfiniteScroll>
            </Card.Body>
        </Card>
    );
};
