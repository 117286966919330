import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {
  CreateOfferMutationVariables,
  DeleteOfferMutationVariables,
  OfferServiceWithTranslations,
  OfferStatus,
  OfferWithTranslations,
  ReadOfferCategoriesQuery,
  ReadOfferCategoriesQueryVariables,
  UpdateOfferMutationVariables,
} from "../types/graphql";
import {useQuery} from "@apollo/client";
import {ReadOfferCategories} from "../graphql/offer";
import {LoadingPage} from "../page/loading";
import {Error} from "./Error";
import {checkPictureUpload} from "../core/utils";
import {OfferServicesTable} from "./OfferServiceTable";
import picture from "../theme/image/picture.png";
import {colors} from "../theme";
import {GetRegionEmoji, RegionType} from "../helper/system";

// Status as options
const statusOptions = [
  {
    name: "Active",
    value: OfferStatus.Active,
  },
  {
    name: "Inactive",
    value: OfferStatus.Inactive,
  },
].map((v) => (
  <option key={v.value} value={v.value}>
    {v.name}
  </option>
));

interface IProps {
  data?: OfferWithTranslations;
  onCreate?: (offerToCreate: CreateOfferMutationVariables) => void;
  onUpdate?: (offerToUpdate: UpdateOfferMutationVariables) => void;
  onDelete?: (offerToDelete: DeleteOfferMutationVariables) => void;
}

export const OfferForm = ({data, onCreate, onUpdate, onDelete}: IProps) => {
  // Form validation
  const [validated, setValidated] = useState(false);
  const pictureImageRef = createRef<HTMLImageElement>();
  const pictureInputRef = createRef<HTMLInputElement>();

  // Form fields
  const [name, setName] = useState(data?.name || "");
  const [categoryId, setCategoryId] = useState(data?.category.id || "");
  const [descriptionEn, setDescriptionEn] = useState(data?.descriptionEn || "");
  const [descriptionNo, setDescriptionNo] = useState(data?.descriptionNo || "");
  const [descriptionSv, setDescriptionSv] = useState(data?.descriptionSv || "");
  const [url, setUrl] = useState(data?.url || "");
  const [pictureBase64, setPictureBase64] = useState<string | null>(null);
  const [status, setStatus] = useState<OfferStatus>(
    data?.status || OfferStatus.Inactive
  );

  // Load categories
  const {
    data: dataCategories,
    loading: loadingCategories,
    error: errCategories,
  } = useQuery<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>(
    ReadOfferCategories
  );

  // Categories as options
  const categoriesOptions = useMemo(() => {
    if (!dataCategories?.categories) return;
    return dataCategories?.categories?.map((v) => (
      <option key={v.id} value={v.id}>
        {GetRegionEmoji(v.region)} {v.nameEn}
      </option>
    ));
  }, [dataCategories]);

  useEffect(() => {
    if (pictureBase64) {
      if (!pictureImageRef.current) return;
      const {naturalWidth: width, naturalHeight: height} =
        pictureImageRef.current;
      if (width !== 100 && height !== 100) {
        alert("Image must have a dimension of 100 x 100 px");
      }
    }
  }, [pictureImageRef, pictureBase64]);

  // On change picture input
  const onChangePicture = useCallback(
    async (e: any) => {
      try {
        const pictureBase64 = await checkPictureUpload(e.target.files, 100);
        setPictureBase64(pictureBase64);
      } catch (e) {
        alert(e);
      }
    },
    [setPictureBase64]
  );

  const isCreationMode = !data;

  // Form submit handler
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    const isFormValid = form.checkValidity();
    setValidated(true);
    if (!isFormValid) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (isCreationMode) {
      if (!pictureBase64) return;
      // Create offer callback
      onCreate?.({
        name,
        categoryId,
        descriptionEn,
        descriptionNo,
        descriptionSv,
        url,
        pictureBase64,
      });
    } else if (data) {
      // Update offer callback
      onUpdate?.({
        offerId: data.id,
        name,
        categoryId,
        descriptionEn,
        descriptionNo,
        descriptionSv,
        url,
        pictureBase64: pictureBase64,
        status,
      });
    }
  };

  const onDeleteClick = () => {
    if (data) {
      onDelete?.({
        offerId: data.id,
        categoryId,
      });
    }
  };

  if (loadingCategories) {
    return <LoadingPage/>;
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Error error={errCategories?.graphQLErrors[0].message}/>

      {/*TODO: Must become a modular component*/}
      <Form.Group>
        <Form.Label>Picture</Form.Label>
        <Form.Control
          type={"file"}
          required={!data?.picture}
          ref={pictureInputRef}
          onChange={onChangePicture}
          hidden>
          <img
            ref={pictureImageRef}
            src={pictureBase64 || data?.picture || picture}
            style={{
              borderRadius: "0.5em",
              padding: "1em",
              backgroundColor: colors.black,
              cursor: "pointer",
              width: "5em",
              height: "5em",
            }}
            onClick={() => {
              // @ts-ignore
              pictureInputRef.current?.click();
            }}
            alt={"Logo"}
          />
          <Form.Text muted>
            A PNG/JPEG picture of dimension 100x100px with max size of 100 KB.
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Please provide a valid picture.
          </Form.Control.Feedback>
        </Form.Control>
      </Form.Group>

      <Row>
        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Company AS"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="offerForm.categoryId">
          <Form.Label>Category</Form.Label>
          <Form.Control
            as="select"
            value={categoryId}
            onChange={(e) => setCategoryId(e.currentTarget.value)}
            required
          >
            <option value="" disabled>
              Choose a category ...
            </option>
            {categoriesOptions}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please provide a valid category.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>

        <Form.Group as={Col} md="6" controlId="offerForm.descriptionEn">
          <Form.Label>Description 🇬🇧</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            plaintext={false}
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid description for english language.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.descriptionNo">
          <Form.Label>Description 🇳🇴</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            plaintext={false}
            value={descriptionNo}
            onChange={(e) => setDescriptionNo(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid description for norwegian language.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.descriptionSv">
          <Form.Label>Description 🇸🇪</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            plaintext={false}
            value={descriptionSv}
            onChange={(e) => setDescriptionSv(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid description for swedish language.
          </Form.Control.Feedback>
        </Form.Group>

      </Row>

      <Row>
        <Form.Group as={Col} md="6" controlId="offerForm.url">
          <Form.Label>URL</Form.Label>
          <Form.Control
            type="url"
            placeholder="https://join.company.as"
            value={url}
            onChange={(e) => setUrl(e.currentTarget.value)}
          />
          <Form.Text muted>
            Offer URL is optional and useless if the offer presents services.
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Please provide a valid URL.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.status">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            value={status.toString()}
            onChange={(e) => setStatus(e.currentTarget.value as OfferStatus)}
            required
            disabled={isCreationMode}
          >
            <option value="" disabled>
              Choose a status ...
            </option>
            {statusOptions}
          </Form.Control>
          <Form.Text muted>
            When active, the offer is shown to users. An offer can be activated
            only after its creation.
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Please provide a valid status.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      {data && (
        <OfferServicesTable
          offerId={data.id}
          region={data.category?.region as RegionType}
          services={data.services as Array<OfferServiceWithTranslations>}
        />
      )}

      <Form.Group>
        {isCreationMode ? (
          <Button type="submit" variant={"dark"}>
            Create
          </Button>
        ) : (
          <>
            <Button type="submit" variant={"dark"}>
              Update
            </Button>{" "}
            <Button variant={"secondary"} onClick={onDeleteClick}>
              Delete
            </Button>
          </>
        )}
      </Form.Group>
    </Form>
  );
};
