import React, { useCallback, useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useLazyQuery, useQuery } from '@apollo/client'
import { ReadBalanceHistory, ReadBalanceHistoryAsExcel, ReadZenExtraBalanceAsExcel } from '../graphql/invoiceTool'
import {
    ReadBalanceHistoryAsExcelQuery,
    ReadBalanceHistoryAsExcelQueryVariables,
    ReadZenExtraBalanceQuery,
    ReadZenExtraBalanceQueryVariables,
} from '../types/graphql'
import { MomentDate } from "./MomentDate";
import { Error } from "./Error";
import { LoadingPage } from "../page/loading";
import { CSSProperties } from 'react'
import { FormatDateAsISO } from "../helper/system";
import { ReadZenExtraBalance } from "../graphql/zenPoints";

interface IProps {
    fromDate: Date,
    toDate: Date,
}


export const ZenBalanceHistory = ({ fromDate, toDate }: IProps) => {

    const {
        data,
        loading,
        error
    } = useQuery<ReadZenExtraBalanceQuery, ReadZenExtraBalanceQueryVariables>(ReadZenExtraBalance, {
        variables: {
            fromDate: FormatDateAsISO(fromDate),
            toDate: FormatDateAsISO(toDate),
        },
    });

    const [showZenpointexts, setShowZenpointexts] = useState<boolean>(false);

    const toggleTransactionsVisibility = useCallback(() => {
        setShowZenpointexts(!showZenpointexts);
    }, [showZenpointexts]);

    const zenpointexts = useMemo(() => {
        if (!data?.readZenExtraBalance.ZenPointExt) return [];

        if (!showZenpointexts) {
            return (
                <tr>
                    <td colSpan={10}>
                        <Button variant="primary" type="submit" onClick={toggleTransactionsVisibility}>
                            Show all transactions ({data.readZenExtraBalance.ZenPointExt.length})
                        </Button>
                    </td>
                </tr>
            );
        }

        const ZenPointExt = data.readZenExtraBalance.ZenPointExt;
        return ZenPointExt.map((zpx, i) => {
            return (
                <tr key={i}>
                    <td>
                        <MomentDate
                            value={zpx?.RemitDate && zpx.RemitDate !== "0001-01-01T00:00:00Z" ? zpx.RemitDate : (zpx?.ZenPoint.CreatedAt || 'N/A')}
                            format={"DD.MM.YY HH:mm:ss"}
                            tooltipId={`tr_${i}`}
                            tooltipFromNow={true}
                        />
                    </td>
                    <td>{zpx?.ZenPoint.Type.Name}</td>
                    <td>
                        {zpx?.ZenPoint.InvoiceId === null ? 'N/A' :
                            <Link to={`/invoices/${zpx?.InvoiceReference}`}>{zpx?.ZenPoint.InvoiceId}</Link>}
                    </td>
                    <td>
                        <Link to={`/user/${zpx?.ZenPoint.UserId}`}>{zpx?.UserName}</Link>
                    </td>
                    <td>{zpx?.IssuerId === null ? 'N/A' :
                        <Link to={`/issuers/${zpx?.IssuerId}`}>{zpx?.IssuerName}</Link>}
                    </td>
                    <td>{zpx?.Amount === null ? 'N/A' : zpx?.Amount}</td>
                    <td>{zpx?.ZenPoint.Points.Extra === null ? 'N/A' : zpx?.ZenPoint.Points.Extra}</td>
                    <td>{zpx?.FeeAmount === null ? 'N/A' : zpx?.FeeAmount}</td>
                    <td>{zpx.ZenFee === null ? 'N/A' : zpx.ZenFee}</td>
                </tr>
            );
        });

    }, [data, showZenpointexts, toggleTransactionsVisibility]);

    const [getAsExcel, {
        loading: getAsExcelLoading,
        error: getAsExcelError,
        data: getAsExcelData
    }] = useLazyQuery<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>(ReadZenExtraBalanceAsExcel, {
        variables: {
            fromDate: FormatDateAsISO(fromDate),
            toDate: FormatDateAsISO(toDate),
        }
    });


    useMemo(() => {
        if (getAsExcelData && getAsExcelData.result && getAsExcelData.result.data) {
            let formatDate = function (d: Date) {
                let month = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
                let day = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
                return "" + d.getFullYear() + "-" + month + "-" + day;
            };
            let a = document.createElement("a");
            a.href = "data:image/png;base64," + getAsExcelData.result.data;
            a.download = "billkill_zen_txs" + formatDate(fromDate) + "_" + formatDate(toDate) + ".xlsx";
            a.click();
        }
    }, [getAsExcelError, getAsExcelData, fromDate, toDate])

    const downloadAsExcel = useCallback(() => {
        getAsExcel()
    }, [getAsExcel, getAsExcelData, getAsExcelLoading, getAsExcelError]);

    const total = useMemo(() => {
        if (!data?.readZenExtraBalance) return;
        const h = data.readZenExtraBalance;
        return (
            <tr>
                <td colSpan={6}>TOTAL</td>
                <td><strong>{(h.TotalUnusedExtra / 1000).toFixed()} NOK</strong></td>
                <td></td>
                <td><strong>{(h.TotalUsedExtra / 1000).toFixed()} NOK</strong></td>
            </tr>
        );
    }, [data]);

    const balance = useMemo(() => {
        if (!data?.readZenExtraBalance) return;
        const h = data.readZenExtraBalance;
        return (
            <tr>
                <td><strong>{(h.TotalEarnedExtra/1000).toFixed(0)} NOK</strong></td>
                <td><strong>{(h.TotalUsedExtra/1000).toFixed(0)} NOK</strong></td>
                <td><strong>{(h.TotalUnusedExtra/1000).toFixed(0)} NOK</strong></td>
            </tr>
        )
    }, [data])

    if (loading) return <LoadingPage variant={'secondary'}/>;
    if (error) return <Error error={error.graphQLErrors[0].message}/>;

    const downloadStyles: CSSProperties = {
        float: 'right',
        marginBottom: '10px'
    }

    return (
        <>
            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>Totalt opptjente ekstrapoeng</th>
                    <th>Brukte ekstrapoeng</th>
                    <th>Ubrukte ekstrapoeng (MBK +30 dgr)</th>
                </tr>
                </thead>
                <tbody>
                {balance}
                </tbody>
            </Table>

            <div style={downloadStyles}>
                <Button variant="primary" type="button" onClick={downloadAsExcel} disabled={getAsExcelLoading}>
                    {getAsExcelLoading ? 'Loading...' : 'Download transactions as Excel'}
                </Button>
            </div>

            <Table responsive striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>Remit/Zen Date</th>
                    <th>Type</th>
                    <th>Invoice ID</th>
                    <th>User</th>
                    <th>Issuer</th>
                    <th>Amount</th>
                    <th>Zen Extra</th>
                    <th>Fees Paid</th>
                    <th>Zen Fee</th>
                </tr>
                </thead>
                <tbody>
                {zenpointexts}
                {total}
                </tbody>
            </Table>
        </>
    );
};
